/* AboutUs.css */

.root-about-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
  background-color: #f5f5f5;
}

.about-title {
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 1rem;
  text-align: center;
  justify-content: center;
  align-items: center ;
}

.about-content {
  height: 80vh;
  width: 80vw;
  font-size: 1rem;
  font-weight: 200;
  margin-bottom: 1rem;
  text-align: center;
}

.about-image {
  height: 30vh;
  border-radius: 10px;
}

.about-image:hover {
  transform: scale(1.1);
  transition: transform 0.5s ease-in-out;
}

.about-section {
  display: flex;
  height: 100vh;
  flex-direction: row;
  align-items: center;
  width: 100vw;
  padding: 1rem;
  margin-bottom: 1rem;
  justify-content: space-evenly;
}

.meet-team-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  background-color: #f5f5f5;
}

.about-section-inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.about-text {
  text-align: left;
  margin-right: 1rem;
  margin-left: 1rem;
}

.about-image-text {
  margin-left: 1rem;
}

.about-us-logo-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.about-us-logo {
  height: 10vh;
  margin-right: 1rem;
  border-radius: 50%;
  cursor: pointer;
}