/* Homepage.css */

.homepage-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 60px;
    margin-bottom: 60px;
  }
  
  .cover-image {
    min-width: 78vw;
    max-height: 80vh;
    overflow: hidden;
    display: flex; /* Add this line to enable centering */
    align-items: center; /* Center content vertically */

  }

  .cover-image::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); /* Adjust the gradient as needed */
    z-index: 1;
  }
  
  .cover-image img {
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: center;
    z-index: 0;
  }
  
  .content-container {
    position: absolute;
    left: 10%;
    max-width: 30vw;
    padding: 20px;
    text-align: center;
    z-index: 2;
  }
  
  .business-name {
    font-size: 2.5em;
    color: #ffffff;
    margin-bottom: 4vh;
  }
  
  .business-description {
    font-size: 1.3em;
    font-weight: 600;
    color: #ffffff;
    padding: 0 20px;
    margin-bottom: 4vh;
  }
  
  .schedule-button {
    padding: 15px 25px;
    font-size: 1.5em;
    font-weight: 600;
    background-color: rgb(73, 146, 255);
    border-radius: 8px;
    color: #fff;
    border: none;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
  }
  
  .schedule-button:hover {
    background-color: #0056b3; /* Change the hover color as needed */
  }
  
  /* MOBILE VERSION */
  @media only screen and (max-width: 600px) {
    .content-container {
      position: absolute;
      left: 0;
      max-width: 100%;
      padding: 20px;
      text-align: center;
      z-index: 2;
      overflow-y: hidden;
    }
    .cover-image img {
      max-height: 80vh;
      height: 60vh;
      transform: scale(1.5);
    }

    .cover-image::before {
      background: linear-gradient(to left, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5)); /* Adjust the gradient as needed */
    }
    
  }

  @media screen and (min-width: 600px) and (max-width: 900px) {
    .content-container {
      flex-direction: row;
      max-width: 100%;
      left: 0;
    }
  }
  