/* Schedule.css */
/* Path: fostersestate/src/schedule/Schedule.js */

/* Main container for the entire schedule page */
.schedule-main-container {
    display: flex;
    align-items: stretch;
    margin-top: 60px;
    height: calc(100vh - 60px);
    background-color: #f5f5f5;
}

/* Container for the image cover on the left (1/3 of the screen) */
.schedule-image-container {
    flex: 1; /* Takes up 1/3 of the available width */
    position: relative;
    overflow: hidden; /* Ensure the container does not overflow its boundaries */
}

.schedule-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 33% 0; 
}

.schedule-image::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.15)); /* Adjust the gradient as needed */
    z-index: 1;
}

/* Container for the schedule form on the right (2/3 of the screen) */
.schedule-container {
    flex: 2;
    display: flex;
    flex-direction: column;
    margin-top: 5vh;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5; /* Set your desired background color */
}

.schedule-form-item {
    width: 50vw;
    margin-bottom: 2vh;
}

@media screen and (max-width: 768px) {
    .schedule-main-container {
        flex-direction: column;
        padding-bottom: 50px;
        display: block;
        height: auto;
    }
    
    .schedule-image-container {
        height: 30vh;
    }

    .schedule-container {
        height: 100vh;
        padding: 20px;
    }

    .schedule-container h1 {
        padding: 0 20px;
        text-align: center;
    }
    
    .schedule-form-item {
        width: 80vw;
    }
}