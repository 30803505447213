/* Contact.css */

.contact-footer {
    background-color: rgba(34, 25, 25, 0.95); /* Set the background color of the footer */
    color: #fff; /* Set text color to white */
    padding: 40px 15vw; /* Padding on top and bottom */
    display: flex;
  }
  
  .contact-info,
  .social-media, .cities {
    flex: 1;
  }
  
  .contact-info p,
  .social-media a {
    margin-bottom: 10px;
    color: #fff;
  }
  
  .social-media a {
    text-decoration: none;
    display: block;
    color: #fff;
  }
  
  .cities-list-container {
    column-count: 2;
    max-width: 300px;

  }

  /*  make list two columns side */
  .cities-list {
    margin: 0;
    display: flex;
  }

  @media screen and (min-width: 600px) {
    .cities-list {
      padding: 0;
    }
  }

  @media screen and (max-width: 600px) {
    .cities-list-container {
      column-count: 2;
    }

    .contact-footer {
      flex-direction: column;
      padding: 40px 5vw;
    }

    .contact-info,
    .social-media,
    .cities {
      margin-bottom: 20px;
    }

    .contact-info, .social-media, .cities {
      text-align: center;
    }

    .social-media a {
      margin: 0 10px;
    }

    .social-media a:last-child {
      margin-right: 0;
    }

    .cities-list {
      justify-content: center;

      list-style: none;


    }
  }