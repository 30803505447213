.section-container {
    padding: 40px;
    border-radius: 8px; /* Add rounded corners to the sections */
    margin: 0 20px; /* Center the content */
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .section {
    flex: 1; /* Take up all available space */
    padding: 20px; /* Add some padding inside the section */
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items on the y-axis */
    text-align: center; /* Center text horizontally */
    justify-content: center; /* Center items on the x-axis */
    display: flex;
  }


  .section-image-container {
    width: 200px;
    height: 200px; /* Set your desired max width */
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .section-image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
  }

  .section-text {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
   
  .section h2 {
    font-size: 1.5em;
    color: #444444;
    max-width: 70%;
    margin: 0 auto;
    margin-top: 20px; 
    max-lines: 2;
  }
  
  .section p {
    font-size: 1.2em;
    color: #7b7b7b;
    max-width: 80%;
  }

  .section:hover {
    background-color: #f1f1f1;
  }
    
  /* animate on hover color change ease in and out */
  .section:hover {
    animation: color-change 0.25s ease-in-out forwards;
  }

  @keyframes color-change {
    from {
      background-color: #f5f5f5;
    }
    to {
      background-color: #eaeaea;
    }
  }

  /* MOBILE STYLES */
  @media screen and (max-width: 600px) {
    .section-container {
      flex-direction: column;
      margin: 0;
      padding: 40px 0;
    }
    .section {
      padding: 0;
    }
    .section-image {
      max-width: 80%;
    }
  }