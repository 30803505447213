/* App.css */

.app-container {
  overflow-y: scroll; /* Enable vertical scrolling */
  height: 100vh; /* Set the height of the container to 100% of the viewport height */
  background-color: #f5f5f5;
}

.icon {
  margin: 8px;
}

.h2 {
  font-size: 1.5em;
  color: #444444;
}